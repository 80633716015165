/**
 * pass all tracks through here in case we need to switch to mixpanel lite.
 * if you are seeing cookie errors in console you can ignore those or clear out
 *  cookies in the mixpanel.com website that fall under the same domain as the tracking endpoint
 */
import queryString from 'query-string';
import { mixpanelWindow } from './mixpanelWindow';
import parseUserClient from './parseUserClient';
import { Cookies } from 'react-cookie';

const mixpanelToken = process.env.MIXPANEL_TOKEN || 'fake_token';
let initCalled = false;

const api_host =
  process.env.NEXT_PUBLIC_LOCAL === 'true' ? undefined : '/api/mixpanel';

export default function initMixpanel() {
  if (typeof window === 'undefined') return; // skip init on server
  if (initCalled) return;
  initCalled = true;
  mixpanelWindow();
  window.mixpanel.init(mixpanelToken, {
    debug: true,
    persistence: 'localStorage',
    api_host,
    loaded: function () {
      window.dry.mpLoaded = true;
      window.dry.events.publish('mixpanel:loaded');
    },
  });
  const user = parseUserClient();
  const cookies = new Cookies();
  const environment = cookies.get('environment') ?? 'unassigned';
  let extcode = null;
  const hrefQueryParms = queryString.parseUrl(document.location.href)?.query;
  if (hrefQueryParms) {
    extcode = hrefQueryParms.extcode;
  }

  /**
   * We have a lot of authentication pages and probably only sign_in calls .identify.
   * Some of those other pages might not have localstorage as the mixpanel storage solution either.
   * Therefore we really just have to call identify on mixpanel load each time.
   */
  if (user?.id_digest) {
    window.mixpanel.identify(user.id_digest);
  }

  window.mixpanel.register({
    id: user?.id,
    external_id: user?.external_id,
    package: user?.package_name,
    email: user?.email,
    user_segment: user?.segment,
    role: user?.role,
    site_key: 'atk',
    extcode,
    environment,
  });

  /**
   * When we initialize and register mixpanel, we need to tell other functions that mixpanel has finished loading
   * the user into super properties before we call these events. There's a queue kept in analytics/mixpanel
   * that subscribes to this event.
   */
  if ('dry' in window) {
    window.dry.events.publish('mixpanel:user:loaded');
  }
}
